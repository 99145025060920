(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var productDetailsShow = function productDetailsShow() {

    /* ======================== Product shadow with absolute positioned info ===================== */
    $(".product-item").mouseover(function () {
        $(this).closest('.products-slider-section').css({ zIndex: '100', overflow: 'visible' });
    });
    $(".product-item").mouseleave(function () {
        $(this).closest('.products-slider-section').css({ zIndex: '0' });
    });

    if (window.matchMedia('(min-width: 1180px)').matches) {
        $(".product-item").mouseover(function () {
            var infoHeight = $(this).find('.product-info').innerHeight();
            $(this).css({ paddingBottom: infoHeight + "px", marginBottom: "-" + infoHeight + "px" });
        });
        $(".product-item").mouseleave(function () {
            $(this).removeAttr('style');
        });
    } else {
        $(".product-item").mouseover(function () {
            $(this).removeAttr('style');
        });
    }
};

exports.default = productDetailsShow;

},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var productsSlider = function productsSlider() {

	$('.products-container').slick({
		infinite: false,
		arrows: true,
		autoplay: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [{
			breakpoint: 1180,
			settings: {
				slidesToShow: 3,
				dots: true,
				arrows: false
			}
		}, {
			breakpoint: 769,
			settings: {
				slidesToShow: 2,
				arrows: false
			}
		}, {
			breakpoint: 567,
			settings: {
				slidesToShow: 1,
				arrows: false
			}
		}]
	});
};

exports.default = productsSlider;

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* ======================== Fixed mobile menus ===================== */
var fixedMenu = function fixedMenu(e) {
  var overlay = $(".overlay"),
      fixedMenuToggleBtn = $(".navmenu-toggle-btn"),
      $body = $(window.document.body),
      menu = $(".fixed-menu"),
      closeBtn = $(".close-fixed-menu"),
      backBtn = $(".back-fixed-menu");

  // Freeze scroll when menu is open
  function bodyFreezeScroll() {
    var bodyWidth = $body.innerWidth();
    $body.css("overflow", "hidden");
    $body.css("paddingRight", ($body.css("paddingRight") ? "+=" : "") + ($body.innerWidth() - bodyWidth));
  }

  // Unfreeze scroll
  function bodyUnfreezeScroll() {
    var bodyWidth = $body.innerWidth();
    $body.removeAttr("style");
  }

  // Back to parent menu after click on back btn
  backBtn.on("click", function (e) {
    $(this).closest(menu).removeClass("active");
  });

  // Hide all menus
  function hideMenu() {
    $body.removeClass("fixed-menu-open").hide;
    $(".fixed-menu").removeClass("active");
    bodyUnfreezeScroll();
  }

  fixedMenuToggleBtn.on("click", function (e) {
    var $this = $(this),
        path = $(this).data("target");
    $body.addClass("fixed-menu-open").show();

    $(path).addClass("active");
    bodyFreezeScroll();
  });

  // Hide menu after click on !this
  overlay.on("click", function () {
    hideMenu();
  });

  //Hide menu after click on close btn
  closeBtn.on("click", function () {
    hideMenu();
  });
};
exports.default = fixedMenu;

},{}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var fixedNav = function fixedNav() {
    var navbar = $(".main-navbar");
    if (!navbar.length) {
        return;
    }
    var offsetTopNavi = navbar.offset().top;
    function fixNavbar() {
        var offsetScroll = $(window).scrollTop();
        $("body")[offsetScroll > offsetTopNavi ? "addClass" : "removeClass"]("fixed-navbar");
    }

    //fixNavbar()
    $(window).on("scroll", function () {
        fixNavbar();
    });
    fixNavbar();
};

exports.default = fixedNav;

},{}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var priceSlider = function priceSlider() {

    // Price Slider
    var priceSlider = $("#price-slider")[0];

    if (priceSlider) {
        var slider = noUiSlider.create(priceSlider, {
            start: [300, 700],
            range: { min: 10, max: 1000 },
            step: 10,
            connect: true,
            format: {
                to: function to(value) {
                    return value.toFixed(2) + ' грн.';
                },
                from: function from(value) {
                    return value;
                }
            }
        });

        var snapValues = [$('#slider-snap-value-lower'), $('#slider-snap-value-upper')];

        //Define initial input value with slider value
        snapValues.map(function (item, i) {
            return item.val(slider.get()[i]);
        });

        //Update input value on slider change
        slider.on("change", function (e) {
            snapValues.map(function (item, i) {
                return item.val(slider.get()[i]);
            });
        });
    }

    // show just first 5 elements
    var maxFiltersItems = 5;

    $('.filter-list-menu').each(function (i, item) {
        if ($(this).find('.filter-list-item').length > maxFiltersItems) {
            $(this).append("<a href='#' class='filters-link more-filters-link'>Показать еще</a>");
            $(this).find('.filter-list-item').slice(maxFiltersItems).hide();
        };

        $(this).find('.filters-link').on('click', function (e) {
            e.preventDefault();
            if ($(this).hasClass('more-filters-link')) {
                $(this).text('Скрыть').removeClass('more-filters-link').addClass('less-filters-link');
                $(this).closest('.filter-section-wrapper').find('.filter-list-item').slice(0).show();
            } else {
                $(this).text('Показать еще').removeClass('less-filters-link').addClass('more-filters-link');
                $(this).closest('.filter-section-wrapper').find('.filter-list-item').slice(maxFiltersItems).hide();
            }
        });
    });

    // Toggle active class for color options && product packages  
    $('.color-options-link, .product-package-btn').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
    });
};

exports.default = priceSlider;

},{}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var fixFiltersHeader = function fixFiltersHeader() {
    var fixedFiltersHeader = $(".products-list-header");
    if (!fixedFiltersHeader.length) {
        return;
    }
    var navBarHeight = $('.main-navbar').innerHeight() + 20;
    var offsetTopHeader = fixedFiltersHeader.offset().top - navBarHeight;
    function fixHeader() {
        var offsetScroll = $(window).scrollTop();
        if (offsetScroll > offsetTopHeader) {
            fixedFiltersHeader.removeClass('bg-md-primary');
            $('body').addClass('fixed-filters-header');
        } else {
            fixedFiltersHeader.addClass('bg-md-primary');
            $('body').removeClass('fixed-filters-header');
        }
    }

    $(window).on("scroll", function () {
        fixHeader();
    });
    fixHeader();
};

exports.default = fixFiltersHeader;

},{}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var commentsSlider = function commentsSlider() {

	$('#comments-slider').slick({
		infinite: true,
		arrows: false,
		autoplay: true,
		slidesToShow: 2,
		dots: true,
		slidesToScroll: 1,
		speed: 500,
		responsive: [{
			breakpoint: 1100,
			settings: {
				slidesToShow: 1,
				dots: true
			}
		}]
	});
};

exports.default = commentsSlider;

},{}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var docsSlider = function docsSlider() {

	$('.docs-container').slick({
		infinite: false,
		arrows: true,
		autoplay: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [{
			breakpoint: 1101,
			settings: {
				slidesToShow: 3
			}
		}, {
			breakpoint: 769,
			settings: {
				slidesToShow: 2
			}
		}, {
			breakpoint: 577,
			settings: {
				slidesToShow: 1
			}
		}]
	});

	$('.docs-container').slickLightbox({
		itemSelector: 'a',
		navigateByKeyboard: true
	});
};

exports.default = docsSlider;

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var newProductsSlider = function newProductsSlider() {

	$('.new-products-container').slick({
		slidesToShow: 1,
		autoplay: true,
		autoplaySpeed: 1000,
		infinite: true,
		speed: 1000,
		dots: true,
		arrows: false,
		fade: true,

		responsive: [{
			breakpoint: 768,
			settings: {
				arrows: false
				//adaptiveHeight: true,
			}
		}]
	});
};

exports.default = newProductsSlider;

},{}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var categoriesSlider = function categoriesSlider() {

	$('.categories-container').slick({
		infinite: false,
		arrows: true,
		autoplay: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		responsive: [{
			breakpoint: 1101,
			settings: {
				slidesToShow: 4,
				arrows: true
			}
		}, {
			breakpoint: 769,
			settings: {
				slidesToShow: 3,
				arrows: true
			}
		}, {
			breakpoint: 576,
			settings: {
				slidesToShow: 2,
				arrows: true
			}
		}]
	});
};

exports.default = categoriesSlider;

},{}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var articlesSlider = function articlesSlider() {

	$('.items-container').slick({
		infinite: true,
		arrows: true,
		autoplaySpeed: 5000,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [{
			breakpoint: 769,
			settings: {
				slidesToShow: 2,
				arrows: true
			}
		}, {
			breakpoint: 577,
			settings: {
				slidesToShow: 1,
				arrows: true
			}
		}]
	});
};

exports.default = articlesSlider;

},{}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// Hero Slider
var slider = function slider() {
    $('#hero-slider').slick({
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        infinite: true,
        speed: 3000,
        dots: true,
        arrows: false,
        fade: true
    });
};

exports.default = slider;

},{}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
var testimonialsSlider = function testimonialsSlider() {

	$('.testimonials-container').slick({
		infinite: true,
		arrows: false,
		autoplay: true,
		slidesToShow: 3,
		dots: true,
		slidesToScroll: 3,
		responsive: [{
			breakpoint: 1101,
			dots: true,
			settings: {
				slidesToShow: 2
			}
		}, {
			breakpoint: 577,
			settings: {
				slidesToShow: 1
			}
		}]
	});
};

exports.default = testimonialsSlider;

},{}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var showAdress = function showAdress() {
    $('.adress-input-group').hide();

    $('#deliveryGroup .custom-control-input').change(function () {
        if ($(this).is(':checked')) {
            var id = $(this).data("target");
            $('.adress-input-group').hide();
            $('#' + id).show();
        }
    });
};

exports.default = showAdress;

},{}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var productMainView = function productMainView() {
  $('.product-main-view').slick({
    infinite: true,
    speed: 300,
    dots: false,
    arrows: false,
    fade: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        arrows: true
      }
    }]
  });

  if (window.matchMedia('(min-width: 1180px)').matches) {
    $('.product-main-view').slickLightbox({
      itemSelector: '.product-view',
      navigateByKeyboard: true
    });
  };

  $('#product-view').slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    focusOnSelect: true,
    asNavFor: '.product-main-view',
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 4
      }
    }]
  });
};

exports.default = productMainView;

},{}],16:[function(require,module,exports){
"use strict";

var _smartResize = require("./main/smartResize");

var _smartResize2 = _interopRequireDefault(_smartResize);

var _preloader = require("./main/preloader");

var _preloader2 = _interopRequireDefault(_preloader);

var _scrollToTab = require("./main/scrollToTab");

var _scrollToTab2 = _interopRequireDefault(_scrollToTab);

var _phoneMask = require("./main/phoneMask");

var _phoneMask2 = _interopRequireDefault(_phoneMask);

var _customSelect = require("./main/customSelect");

var _customSelect2 = _interopRequireDefault(_customSelect);

var _popover = require("./main/popover");

var _popover2 = _interopRequireDefault(_popover);

var _menu = require("../layout/header/navbar/menu");

var _menu2 = _interopRequireDefault(_menu);

var _index = require("../layout/header/mobile_menu/index");

var _index2 = _interopRequireDefault(_index);

var _index3 = require("../pages/main/sections/slider/index");

var _index4 = _interopRequireDefault(_index3);

var _index5 = require("../pages/main/sections/popular_categories/index");

var _index6 = _interopRequireDefault(_index5);

var _index7 = require("../pages/main/sections/new_products/index");

var _index8 = _interopRequireDefault(_index7);

var _index9 = require("../components/product/index");

var _index10 = _interopRequireDefault(_index9);

var _index11 = require("../components/products_slider/index");

var _index12 = _interopRequireDefault(_index11);

var _index13 = require("../pages/main/sections/reconstruction_programs/index");

var _index14 = _interopRequireDefault(_index13);

var _index15 = require("../pages/main/sections/docs/index");

var _index16 = _interopRequireDefault(_index15);

var _index17 = require("../pages/main/sections/testimonials/index");

var _index18 = _interopRequireDefault(_index17);

var _index19 = require("../pages/catalog/filters_menu/index");

var _index20 = _interopRequireDefault(_index19);

var _index21 = require("../pages/catalog/testimonials/index");

var _index22 = _interopRequireDefault(_index21);

var _index23 = require("../pages/catalog/products_list_header/index");

var _index24 = _interopRequireDefault(_index23);

var _index25 = require("../pages/product/slider/index");

var _index26 = _interopRequireDefault(_index25);

var _index27 = require("../pages/order/index");

var _index28 = _interopRequireDefault(_index27);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//import touchSwipe from './main/touchSwipe';
$(window).ready(function () {
  (0, _preloader2.default)();
});

(0, _smartResize2.default)();

(0, _scrollToTab2.default)();
(0, _phoneMask2.default)();
(0, _customSelect2.default)();
(0, _popover2.default)();

(0, _menu2.default)();
(0, _index2.default)();
(0, _index4.default)();
(0, _index6.default)();
(0, _index8.default)();
(0, _index10.default)();
(0, _index12.default)();
(0, _index14.default)();
(0, _index16.default)();
(0, _index18.default)();
(0, _index20.default)();
(0, _index22.default)();
(0, _index26.default)();
(0, _index24.default)();
(0, _index28.default)();

$(window).smartresize(function () {
  (0, _index10.default)();
  (0, _index24.default)();
  if (window.matchMedia("(max-width: 1100px)").matches) {
    $("#cartModal").modal("hide");
  }
  $(".fixed-menu").css("transition", "none");
});

$(window).resize(function () {
  if (this.resizeTO) clearTimeout(this.resizeTO);
  this.resizeTO = setTimeout(function () {
    $(this).trigger("resizeEnd");
  }, 500);
});

$(window).bind("resizeEnd", function () {
  $(".fixed-menu").css("transition", "all .3s ease-in");
});

},{"../components/product/index":1,"../components/products_slider/index":2,"../layout/header/mobile_menu/index":3,"../layout/header/navbar/menu":4,"../pages/catalog/filters_menu/index":5,"../pages/catalog/products_list_header/index":6,"../pages/catalog/testimonials/index":7,"../pages/main/sections/docs/index":8,"../pages/main/sections/new_products/index":9,"../pages/main/sections/popular_categories/index":10,"../pages/main/sections/reconstruction_programs/index":11,"../pages/main/sections/slider/index":12,"../pages/main/sections/testimonials/index":13,"../pages/order/index":14,"../pages/product/slider/index":15,"./main/customSelect":17,"./main/phoneMask":18,"./main/popover":19,"./main/preloader":20,"./main/scrollToTab":21,"./main/smartResize":22}],17:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var customSelect = function customSelect() {
    if ($('.four-boot-select').length) {
        $('.four-boot-select').fourBoot({
            class: "btn-outline-secondary"
        });
    }
};

exports.default = customSelect;

},{}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var phoneMask = function phoneMask() {
    $('.phone').inputmask({
        autoUnmask: true,
        showMaskOnHover: false,
        showMaskOnFocus: true,
        mask: '+3(099)999-99-99'
    });
};

exports.default = phoneMask;

},{}],19:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var popover = function popover() {
    $("#brand-info").popover({
        trigger: 'click'
    });
};

exports.default = popover;

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var handlePreloader = function handlePreloader() {
    if ($('.preloader').length) {
        $('.preloader').fadeOut(1000);
    }
};

exports.default = handlePreloader;

},{}],21:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var scrollToTab = function scrollToTab() {
    $("a[data-scroll]").click(function (e) {
        e.preventDefault();
        var id = $(this).data("scroll");
        $('html,body').animate({
            scrollTop: $("#" + id).offset().top - 80 }, 'slow');
        $("#" + id).trigger("click");
    });
};
exports.default = scrollToTab;

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var smartResize = function smartResize() {
    (function ($, sr) {

        // debouncing function from John Hann
        // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
        var debounce = function debounce(func, threshold, execAsap) {
            var timeout;

            return function debounced() {
                var obj = this,
                    args = arguments;
                function delayed() {
                    if (!execAsap) func.apply(obj, args);
                    timeout = null;
                };

                if (timeout) clearTimeout(timeout);else if (execAsap) func.apply(obj, args);

                timeout = setTimeout(delayed, threshold || 100);
            };
        };
        // smartresize 
        jQuery.fn[sr] = function (fn) {
            return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
        };
    })(jQuery, 'smartresize');
};
exports.default = smartResize;

},{}]},{},[16]);
